.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.whatsapp_floatt {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 165px;
  right: 40px;
  background-color: skyblue;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 100px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
      margin-top: 10px;
  }

  .whatsapp_float {
      width: 40px;
      height: 40px;
      bottom: 100px;
      right: 50px;
      font-size: 22px;
  }
}

@keyframes mymove {
  50% {box-shadow: 10px 20px 30px blue;}
}

@media screen and (max-width: 767px) {
 .ff{
  margin-top: 10px;
 }
  .whatsapp_floatt {
      width: 40px;
      height: 40px;
      bottom: 150px;
      right: 50px;
      font-size: 22px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
