* {
    box-sizing: border-box;
  }
  
  /* Style the body */
  body {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
  }
  
  /* Header/logo Title */
  .headerr {
    /* max-width: 1000px; */
  position: relative;
  margin: auto;
    padding: 50px;
    text-align: center;
    background: #1abc9c;
    color: white;
  }

  
  /* Increase the font size of the heading */
  .headerr h1 {
    font-size: 40px;
  }
  
  /* Style the top navigation bar */
  .navbarr {
    overflow: hidden;
    background-color: #333;
  }
  
  /* Style the navigation bar links */
  .navbarr a {
    float: left;
    display: block;
    color: white;
    text-align: center;
    padding: 14px 20px;
    text-decoration: none;
  }
  
  /* Right-aligned link */
  .navbarr a.right {
    float: right;
  }
  
  /* Change color on hover */
  .navbarr a:hover {
    background-color: #ddd;
    color: black;
  }
  
  /* Column container */
  .roww {  
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
  }
  
  /* Create two unequal columns that sits next to each other */
  /* Sidebar/left column */
  .side {
    -ms-flex: 30%; /* IE10 */
    flex: 30%;
    background-color: #f1f1f1;
    padding: 20px;
  }
  
  /* Main column */
  .main {   
    -ms-flex: 70%; /* IE10 */
    flex: 70%;
    background-color: white;
    padding: 20px;
  }
  
  /* Fake image, just for this example */
  .fakeimg {
    background-color: #aaa;
    width: 100%;
    padding: 20px;
  }
  
  /* Footer */
  .footerr {
    padding: 20px;
    text-align: center;
    background: #ddd;
  }
  
  /* Responsive layout - when the screen is less than 700px wide, make the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 700px) {
    .roww {   
      flex-direction: column;
    }
  }

 
  /* Responsive layout - when the screen is less than 400px wide, make the navigation links stack on top of each other instead of next to each other */
  @media screen and (max-width: 400px) {
    .navbarr a {
      float: none;
      width: 100%;
    }
  }